// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-copy-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\agency - Copy.js" /* webpackChunkName: "component---src-pages-agency-copy-js" */),
  "component---src-pages-agency-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-app-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-appclassic-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\appclassic.js" /* webpackChunkName: "component---src-pages-appclassic-js" */),
  "component---src-pages-appmodern-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\appmodern.js" /* webpackChunkName: "component---src-pages-appmodern-js" */),
  "component---src-pages-charity-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\charity.js" /* webpackChunkName: "component---src-pages-charity-js" */),
  "component---src-pages-crypto-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-cryptomodern-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\cryptomodern.js" /* webpackChunkName: "component---src-pages-cryptomodern-js" */),
  "component---src-pages-hosting-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-old-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-index-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\interior.js" /* webpackChunkName: "component---src-pages-interior-js" */),
  "component---src-pages-page-2-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-ride-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\ride.js" /* webpackChunkName: "component---src-pages-ride-js" */),
  "component---src-pages-saas-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-saasclassic-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\saasclassic.js" /* webpackChunkName: "component---src-pages-saasclassic-js" */),
  "component---src-pages-saasmodern-js": () => import("D:\\xampp\\htdocs\\susai\\conqhr3\\packages\\landing-gatsby\\src\\pages\\saasmodern.js" /* webpackChunkName: "component---src-pages-saasmodern-js" */)
}

